import { template as template_1f9fe0bd97084870976528f3e230e26d } from "@ember/template-compiler";
import FKText from "discourse/form-kit/components/fk/text";
const FKFieldset = template_1f9fe0bd97084870976528f3e230e26d(`
  <fieldset name={{@name}} class="form-kit__fieldset" ...attributes>
    {{#if @title}}
      <legend class="form-kit__fieldset-title">{{@title}}</legend>
    {{/if}}

    {{#if @description}}
      <FKText class="form-kit__fieldset-description">
        {{@description}}
      </FKText>
    {{/if}}

    {{yield}}
  </fieldset>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKFieldset;
