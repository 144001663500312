import { template as template_f45c2995a6ab417aa7879340e2ea52dd } from "@ember/template-compiler";
const FKLabel = template_f45c2995a6ab417aa7879340e2ea52dd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
