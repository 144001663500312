import { template as template_bc4037aac0a34f16b78fb1b98c17aabf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_bc4037aac0a34f16b78fb1b98c17aabf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
